import BaseService from "./BaseService";

const api = "stats/";

export function IndustrialStats() {
  return BaseService.get(`${api}industrial`);
}

export function ArtesanalBoatsStats() {
  return BaseService.get(`${api}artesanal`);
}

export function FishermanStats(type) {
  return BaseService.get(`${api}fisherman/${type}`);
}
