<template>
  <div>
    <v-card class="mx-auto">
      <v-card-title class="headline">
        {{
          type == "art"
            ? "Embarcaciones artesanales registradas"
            : "Embarcaciones industriales con permiso vigente"
        }}
      </v-card-title>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6" v-for="(chart, i) in charts" :key="`chart_${i}`">
                <highcharts :options="chart"></highcharts>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-title class="headline">Puertos de desembarque</v-card-title>
        <v-col cols="12" >
      <div v-if="ports.length > 0 " style="position: sticky;max-width: 800px;
        margin: auto;" >
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css">
              <l-map style="height: 300px" :zoom="zoom" :minZoom="7" :center="center">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <div v-for="port in ports" :key="port.id">
                
                  <l-marker v-if="port.latitude != null && port.longitude != null" :lat-lng="[port.latitude, port.longitude]"  :icon="defaultIcon">
                    <l-tooltip :options="{ permanent: false, interactive: true }">{{port.nombre}} | <v-icon x-small>fa fa-ship</v-icon>  <span style="font-size: 14px">{{port.total}}</span> </l-tooltip>
                  </l-marker>
                </div>
              </l-map>
            </div>
        </v-col>
       
      <boats-table ref="boatsTable" :type="type" :customHeaders="headers"></boats-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Highcharts3D from "highcharts/highcharts-3d";
import Highcharts from "highcharts";
import getFields from "../services/exportboats";

import { consultar } from "../services/variablesconcepto";

import { IndustrialStats, ArtesanalBoatsStats } from "../services/StatsService";
import {
  getLandingZones,
  getPortsOfLanding,
  getRegistrationPort,
} from "../services/DataSourceService";
import BoatsTable from './BoatsTable.vue';

//para los mapas

import {LMap, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet';
import icon from "../assets/anchor.png";
import L from "leaflet";

Highcharts3D(Highcharts);

var options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Tipo de Pesquería",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
      group: [],
    },
  ],
};
export default {
  components: { BoatsTable, LMap, LTileLayer, LMarker, LTooltip },
  props: ["type"],
  data: () => ({
    //empieza mapas
    defaultIcon: L.icon({
      iconUrl: icon,
      iconSize: [24, 24],
      iconAnchor: [13, 42]
    }),
    ports: [],
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 7,
    center: [12.548883, -81.718130],
    //termino mapas
    searchBoat: "",
    json_fields: {},
    search: "",
    filtrado: [],
    dialog: false,
    concepto: {},
    options: options,
    numBarcosAutorized: "",
    items_select_authorized_landing_port_ind: [],
    items_select_authorized_landing_port: [],
    items_select_registration_port: [],
    charts: []
  }),
  computed: {
    ...mapGetters([
      "allBoats",
    ]),
    headers(){
      return [
      { text: "Nombre de la Embarcación", value: "boat_name" },
      { text: "Matriculada", value: "matriculada" },
      { text: "No. Matricula", value: "registration_number" },
      { text: "Puerto de Desembarque", value: this.type == 'ind' ?  "company.current_permit.puerto_desembarque_industrial.nombre" : "company.current_permit.puerto_desembarque_artesanal.nombre"},
      { text: "Tipo de Pesquería", value: "pesqueria" },
      { text: "TRN", value: "net_registered_tonnage" },
    ]
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    type: async function (valueType) {
      const boatsType = this.$refs.boatsTable.getBoatType(valueType);
      this.$refs.boatsTable.filterBoats(boatsType);
      await this.plotStats();
    },
  },

  created: async function () {
    this.json_fields = (await getFields())?.json_fields;
    if (this.type != "ind" && this.type != "art") {
      this.$router.go(-1);
    }
    await this.plotStats();
    this.concepto = await consultar.fetch();
    this.items_select_authorized_landing_port =
      (await getLandingZones())?.data || [];
    this.items_select_authorized_landing_port_ind =
      (await getPortsOfLanding())?.data || [];
    this.items_select_registration_port =
      (await getRegistrationPort())?.data || [];
  },

  methods: {
    async plotStats() {
      this.charts = [];

      const graficas = {
        artes_IND: { name: "artes", type: "IND", title: "Artes de Pesca" },
        pabellon_IND: { name: "pabellon", type: "IND", title: "Pabellones" },
        puerto_IND: {
          name: "puerto",
          type: "IND",
          title: "Puerto de Desembarque",
        },
        artes_ART: {
          name: "artes",
          type: "ART",
          title: "Artes de Pesca Artesanal",
        },
        desembarque_ART: {
          name: "desembarque",
          type: "ART",
          title: "Puerto de Desembarque",
        },
        registro_ART: {
          name: "registro",
          type: "ART",
          title: "Puerto de Registro",
        },
      };

      const mapToSerieObject = (x) => {
        let _key = Object.keys(x)[0];
        return {
          key: _key,
          name: `${_key}:${x[_key]}`,
          y: x[_key],
          sliced: true,
        };
      };

      let stats =
        this.type.toUpperCase() === "IND"
          ? (await IndustrialStats())?.data?.industrial
          : (await ArtesanalBoatsStats())?.data?.artesanal;

      for (const item in stats) {
        let type = this.type.toUpperCase();
        let index = `${item}_${type}`;
        let chart = graficas[index];
        let data = [];
        
        if(index == 'desembarque_ART')
        {
          data = stats[chart?.name];
          this.ports = data;
        }
        else
        {
          data = stats[chart?.name].map(mapToSerieObject);
          this.options.title.text = chart.title;
          this.options.series[0].data = data || [];

          this.charts.push(JSON.parse(JSON.stringify(this.options)));
        }
      }
    },
  },
};
</script>
